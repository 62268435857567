import React from "react";
import { graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import MetaTags from '../components/MetaTags';
import Banner from '../components/Banner';
import Posts from '../components/Posts';
import { selectPosts } from '../selectors/selectPosts';

export const query = graphql`
  query($tag: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___datetime], order: DESC }
      filter: {
        fileAbsolutePath: { regex:  "\/content/posts/" }
        frontmatter: { tags: { in: [$tag] } }
      }
    ) {
      ...ALL_POSTS_FRAGMENT
    }
  }
`;

const TagTemplate = ({
  data,
  location: {
    href
  },
  pageContext: {
    tag
  }
}) => {
  const { posts } = selectPosts(data);
  const title = `${tag} posts`;
  const description = `All posts on Assortment tagged with ${tag}`; 

  return (
    <PageLayout>
      <MetaTags 
        url={href}
        metaTitle={title}
        metaDescription={description}
      />
      <Banner
        title={title}
        excerpt={description}
        link={{
          href: '/tags',
          text: 'View all tags'
        }}
      />
      <Posts posts={posts} />
    </PageLayout>
  );
};

export default TagTemplate;
